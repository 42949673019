import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div className="lex flex-col min-h-screen text-black">
      <Header />

      <main className="flex flex-col flex-1 md:justify-center max-w-5xl mx-auto px-4 pb-8 pt-2 md:px-8 md:pb-8 md:pt-0 w-full">
        {children}
      </main>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
